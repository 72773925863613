/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Loading from '../../Loading/Loading';

function Temoignage() {
    const [temoignages, setTemoignages] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [isPending, setPending] = useState(true)


    useEffect(() => {
        axios
            .get(`https://irakoze.burundientempsreel.com/api/getalltemoignage?page=${currentPage}`)
            .then((response) => {
                const { temoignages, totalPages } = response.data;
                setTemoignages(temoignages);
                setTotalPages(totalPages);
                setTimeout(() => {
                    setPending(false)
                }, 100)
            })
            .catch((error) => {
                console.log(error.message);
            });
    }, [currentPage]);

    // Fonction pour passer à la page suivante
    const nextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    // Fonction pour passer à la page précédente
    const previousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    // Fonction pour changer de page lors du clic sur un numéro de page spécifique
    const goToPage = (page) => {
        setCurrentPage(page);
    };

    // Générer la liste de numérotation des pages
    const generatePageNumbers = () => {
        const pageNumbers = [];
        for (let i = 1; i <= totalPages; i++) {
            pageNumbers.push(
                <button
                    key={i}
                    onClick={() => goToPage(i)}
                    className={currentPage === i ?
                        "bg-orange-700 w-[30px] h-[30px] flex justify-center items-center rounded-full text-white" : "bg-gray-200 hover:bg-gray-300 w-[30px] h-[30px] flex justify-center items-center rounded-full"}
                >
                    {i}
                </button>
            );
        }
        return pageNumbers;
    };

    function transformDate(isoDate) {
        const date = new Date(isoDate);
        const frenchMonths = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'];
        const frenchDays = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'];
        const day = frenchDays[date.getDay()];
        const dayNumber = date.getDate();
        const year = date.getFullYear();
        let hours = date.getHours();
        const minutes = date.getMinutes();
        hours = hours > 9 ? hours : '0' + hours;

        let time = `${hours}h${minutes}`
        return (<>
            <p className='mx-2'>
                {day}, {'Le'} {dayNumber}/0{date.getMonth() + 1}/{year}
            </p>
            <p className='ml-2'>
                à {time}
            </p>
        </>
        )
    }

    const [mobile1, SetMobile1] = useState(window.innerWidth < 420.90837383833)
    const [mobile, SetMobille] = useState(window.innerWidth < 374)
    useEffect(() => {

        const hundleSize = () => {
            SetMobille(window.innerWidth < 374)
            SetMobile1(window.innerWidth < 420.90837383833)
        }
        window.addEventListener('resize', hundleSize)

        return () => {
            window.removeEventListener('resize', hundleSize)
        }
    }, [])
    return (
        <>
            <div className='bg-white p-1 flex flex-col justify-center items-center '>
                {isPending && <Loading />}
                <h2 className='w-full p-2 text-center text-[30px]' >Les témoignages</h2>

                {(temoignages.length > 0) ? (
                    temoignages.map((value) => {
                        return (
                            <div className={`w-[95%] cursor-default select-none temoign ${mobile ? 'my-1 p-1' : ' my-5 p-10'}`}>
                                <p className='text-gray-400 mb-5 w-full text-left cursor-default select-none'>{value.titre}</p>
                                <div className="text-gray-800 w-full text-left">
                                    {value.contenue}



                                    <p className='text-gray-400 my-5 cursor-default select-none flex w-full text-left'>
                                        <p className='inline-flex mr-5'>{value.nom}{" "} {value.prenom}</p>

                                        <span className='inline-flex'>
                                            {transformDate(value.createdAt)} </span>
                                    </p>

                                </div>
                            </div>
                        )
                    })

                ) : (
                    <div className={`w-[95%] cursor-default select-none temoign ${mobile ? 'my-1 p-1' : ' my-5 p-10'}`}>
                        <p className='text-gray-400 mb-5 cursor-default select-none'>Remerciement</p>
                        <div className="text-gray-800">
                            Cher Lycée d'Excellence NGAGARA,

                            Je tenais à vous exprimer ma profonde gratitude et mes sincères remerciements pour raison du remerciement.
                            Votre soutien, votre générosité et votre présence ont été d'une importance capitale pour moi/mon projet/ma réussite.
                            Votre aide, conseils, contributions, présence ont véritablement fait la différence et m'ont permis d'atteindre objectif, résultat, étape.
                            Votre engagement et votre soutien inconditionnel ont été une source d'inspiration et de motivation pour moi, et je suis extrêmement reconnaissant(e) de vous avoir à mes côtés.
                            <br /><br />
                            Je suis conscient(e) que sans votre aide, je n'aurais pas pu accomplissement. Votre confiance en moi et votre encouragement ont renforcé ma détermination et m'ont donné la force nécessaire pour surmonter les obstacles.
                            Votre soutien indéfectible a été un cadeau précieux et je tiens à vous assurer que je suis profondément reconnaissant(e) de votre présence dans ma vie/mon projet/mon parcours. Votre contribution a eu un impact significatif et durable, et je ne saurais trop vous remercier pour tout ce que vous avez fait.
                            Je souhaite donc vous exprimer toute ma gratitude et vous témoigner toute mon admiration pour votre générosité et votre bienveillance. Je suis honoré(e) d'avoir une personne aussi extraordinaire à mes côtés, et je suis conscient(e) de la chance que j'ai de vous avoir dans ma vie.

                            <br /><br />
                            Encore une fois, du fond du cœur, merci pour tout. Votre soutien m'a énormément touché(e) et je ne l'oublierai jamais.

                            Avec mes plus sincères remerciements,

                            <p className='text-gray-400 my-5 cursor-default select-none flex '>
                                <p className='inline-flex mr-5'>NDAYIZEYE Télésphore</p>
                                <span className='inline-flex'> <p className='mx-2'>Samedi,Le 20/05/2023</p> à <p className='ml-2'> 19h:21</p> </span>
                            </p>

                        </div>
                    </div>)}


                <div className={`  ${mobile1 ? 'w-[95%]' : 'w-[25em]'}`}>
                    <div class=" w-full h-full flex justify-around items-center p-2">
                        <button onClick={previousPage} disabled={currentPage === 1} class=" transition-all flex cursor-pointer hover:bg-gray-200 p-2 rounded">
                            <a href="#" class="mr-1">&laquo;</a>
                            <p>Precedant</p>
                        </button>
                        <button class="pagination-link">
                            {generatePageNumbers()}
                        </button>
                        <button onClick={nextPage} disabled={currentPage === totalPages} class=" transition-all flex cursor-pointer hover:bg-gray-200 p-2 rounded">
                            <p>Suivant</p>
                            <a href="#" class="ml-1">&raquo;</a>
                        </button>
                    </div>

                </div>
                <p className='w-[90%] flex justify-end items-center'>

                    <Link to="/Visit/NouvelleTemoignage" className='transition-all bg-orange-400 hover:bg-orange-600 p-2 rounded text-white'>Votre témoignage</Link>
                </p>

            </div>
        </>
    )
}

export default Temoignage
