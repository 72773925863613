import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Loading from '../../Loading/Loading';
import { Link } from 'react-router-dom';
import EmpyServices from '../EmpyServeices/EmpyServeices';
function Temoignage() {
    const [lasttemoignage, setLasttemoignage] = useState([]);
    const [isPending, setPending] = useState(true)
    const [mobile3, setMobille3] = useState(window.innerWidth < 443);
    useEffect(() => {
        axios
            .get('https://irakoze.burundientempsreel.com/api/lasttemoignage')
            .then((response) => {
                setLasttemoignage(response.data);
                setTimeout(() => {
                    setPending(false)
                }, 100)
            })
            .catch((error) => {
                console.error(error);
            });
    }, []);

    useEffect(() => {

        const hundleSize = () => {
            setMobille3(window.innerWidth < 443)
        }

        window.addEventListener('resize', hundleSize)
        return () => {
            window.removeEventListener('resize', hundleSize)
        }

    }, [])
    return (
        <>
            {lasttemoignage.length > 0 ? (
                lasttemoignage((temo) => {
                    <div className="w-[97%]  bg-gray-50 rounded-lg  mb-3 flex flex-col mt-[20px]  justify-center items-center ">
                        {isPending && <Loading />}
                        <h2 className="text-[30px]  px-4 p-1 my-2 rounded">Témoignage</h2>
                        <div className='h-[1px] mb-1  bg-gray-300 w-full'></div>
                        <div className="responsive1 border border-orange-600  p-2 w-full">
                            <div className=" w-[80%] h-max  rounded overflow-hidden">
                                <p className='pl-3'>
                                    <div className='font-bold text-[25px]'>Auteur</div>
                                    <div className=' text-blue-900  text-[22px]'>{temo.nom} {" "} {temo.prenom}{" "}</div>
                                    <div className=' text-rose-900 text-[22px]'> {temo.categorie}</div>
                                </p>
                            </div>
                            <div className="w-[95%]">
                                <div className='  font-bold underline italic text-1xl'>{temo.titre}</div>
                                <div className="">
                                    <div className=' px-4 text-gray-700 text-[19px]' >
                                        {temo.contenue && temo.contenue.length > 100
                                            ? temo.contenue.slice(0, 100) + '...'
                                            : temo.contenue}
                                    </div>
                                    <div className="  w-full flex justify-center m-4">
                                        <Link to='/Visit/Temoignage' className={`bg-orange-400 ${mobile3 ? 'w-[80%]' : 'w-[20em]'} text-[20px] mb-4  py-2  text-center rounded-3xl cursor-pointer text-white  hover:border-orange-600 hover:bg-white transition-all hover:text-gray-700 border border-white `}>Plus de témoignages</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                })
            ) : (
                <>

                    <div className="w-[97%]  bg-gray-50 rounded-lg pb-10 flex flex-col mt-[20px]  justify-center items-center ">
                        <h2 className="text-[30px]  px-4 p-1 my-2 rounded">Témoignage</h2>
                        <div className='h-[1px] mb-1  bg-gray-300 w-full'></div>
                        <div className="responsive1 flex justify-center  p-2 w-full">
                            <div className='flex  flex-col items-center w-[50em] widthsssssssss border border-orange-600 p-3 rounded-lg bg-slate-100 h-max'>
                                <EmpyServices items="de témoignage" />
                                <div className='text-[18px] font-sans text-gray-700'>
                                    Cher Lycée d'Excellence NGAGARA,

                                    Je tenais à vous exprimer ma profonde gratitude et mes sincères remerciements pour raison du remerciement.
                                    Votre soutien, votre générosité et votre présence ont été d'une importance capitale pour moi/mon projet/ma réussite.
                                    Votre aide, conseils, contributions, présence ont véritablement fait la différence et m'ont permis d'atteindre objectif, résultat, étape.
                                    Votre engagement et votre soutien inconditionnel ont été une source d'inspiration et de motivation pour moi, et je suis extrêmement reconnaissant(e) de vous avoir à mes côtés.
                                    <br /><br />
                                    Je suis conscient(e) que sans votre aide, je n'aurais pas pu accomplissement. Votre confiance en moi et votre encouragement ont renforcé ma détermination et m'ont donné la force nécessaire pour surmonter les obstacles.
                                    Votre soutien indéfectible a été un cadeau précieux et je tiens à vous assurer que je suis profondément reconnaissant(e) de votre présence dans ma vie/mon projet/mon parcours. Votre contribution a eu un impact significatif et durable, et je ne saurais trop vous remercier pour tout ce que vous avez fait.
                                    Je souhaite donc vous exprimer toute ma gratitude et vous témoigner toute mon admiration pour votre générosité et votre bienveillance. Je suis honoré(e) d'avoir une personne aussi extraordinaire à mes côtés, et je suis conscient(e) de la chance que j'ai de vous avoir dans ma vie.

                                    <br /><br />
                                    Encore une fois, du fond du cœur, merci pour tout. Votre soutien m'a énormément touché(e) et je ne l'oublierai jamais.

                                    Avec mes plus sincères remerciements,      </div>
                                <div className="  w-full flex justify-center m-4">
                                    <Link to='/Visit/NouvelleTemoignage' className={`bg-orange-400 ${mobile3 ? 'w-[80%] text-[15px]' : 'w-[20em] text-[20px]'}  mb-4  py-2  text-center rounded-3xl cursor-pointer text-white  hover:border-orange-600 hover:bg-white transition-all hover:text-gray-700 border border-white `}>Laisse votre témoignages</Link>
                                </div>
                            </div>

                        </div>
                    </div>
                </>

            )}

        </>
    );
}

export default Temoignage;
