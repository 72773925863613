import React from 'react'
import HomComponent from '../../component/Visitor/Home/HomComponent'
function HomeVisitorPage() {
  return (
    <>
      <HomComponent />
    </>
  )
}

export default HomeVisitorPage
