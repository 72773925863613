import React from 'react'
import { useState } from 'react';
import { toast } from 'react-toastify';
import Select from 'react-select';
import axios from 'axios';

const Options = [
    { value: 'Elève', label: 'Elève' },
    { value: 'Parent', label: 'Parent' },
    { value: 'Professeur', label: 'Professeur' },
    { value: 'Particulier', label: 'Particulier' }
]

const customStyles = {
    control: (provided, state) => ({
        ...provided,
        borderBottom: '2px solid gray',
        borderRadius: '0px',
        backgroundColor: "transparent",
        top: '7px',
        with: '100%',
        boxShadow: state.isFocused ? '0 0 0 2px #ddd' : 'none',
        borderBottom: state.isFocused ? '2px solid blue' : '2px solid gray',
        '&:hover': {
            borderBottom: state.isFocused ? '2px solid blue' : '2px solid orange',
        },
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? 'orange' : 'transparent',
        color: state.isSelected ? '#333' : '#666',
        '&:hover': {
            backgroundColor: '#f5f5f5',
        },
        position: 'static',
        zIndex: '10000',
    }),
};
function FormulaireTemoignVisitores() {


    const [isTitre, SetisTitre] = useState('')
    const [animationClassTitre, setAnimationClassTitre] = useState('');

    const [isNom, SetisNom] = useState('')
    const [animationClassNom, setAnimationClassNom] = useState('');

    const [isPrenom, SetisPrenom] = useState('')
    const [animationClassPrenom, setAnimationClassPrenom] = useState('');

    const [isSelectValue, SetisSelectValue] = useState('')
    const [animationClassSelecteValue, setanimationClassSelecteValue] = useState('');

    const [isTextarea, SetisTextarea] = useState('')
    const [animationClassTextarea, setAnimationClassTextarea] = useState('');

    const [autoScrollHeight, setautoScrollHeight] = useState(50);
    const [valueTextarea, setvalueTextarea] = useState('');



    const handleSubmit = (e) => {
        e.preventDefault()
        if (isTitre.trim() === '') {
            toast.warning('Le titre de témoignage est obligatoire', {
                autoClose: 2000
            });
            setAnimationClassTitre('animate__animated animate__shakeX placeholder-shown:border-red-500')
            setTimeout(() => {
                setAnimationClassTitre(' ')
            }, 3000)
            return;
        } else if (isNom.trim() === '') {
            toast.warning('Le nom est obligatoire', {
                autoClose: 2000
            });
            setAnimationClassNom('animate__animated animate__shakeX placeholder-shown:border-red-500')
            setTimeout(() => {
                setAnimationClassNom(' ')
            }, 3000)
            return;
        } else if (isPrenom.trim() === '') {
            toast.warning('Le  prenom est obligatoire', {
                autoClose: 2000
            });
            setAnimationClassPrenom('animate__animated animate__shakeX placeholder-shown:border-red-500')
            setTimeout(() => {
                setAnimationClassPrenom(' ')
            }, 3000)
            return;
        }
        else if (isSelectValue === '') {
            toast.warning('Sélectionnez votre catégorie', {
                autoClose: 2000
            });
            setanimationClassSelecteValue('animate__animated animate__shakeX border-red-500')
            setTimeout(() => {
                setanimationClassSelecteValue(' ')
            }, 3000)
            return;
        }
        else if (isTextarea.trim() === '') {
            toast.warning('Le contenue de la temoignage est obligatoire', {
                autoClose: 2000
            });
            setAnimationClassTextarea('animate__animated animate__shakeX placeholder-shown:border-red-500')
            setTimeout(() => {
                setAnimationClassTextarea(' ')
            }, 3000)
            return;
        }
        axios.post('https://irakoze.burundientempsreel.com/api/addtemoignage', {
            nom: isNom,
            prenom: isPrenom,
            categorie: isSelectValue.value,
            titre: isTitre,
            contenue: isTextarea

        }).then((Response) => {
            toast.success(Response.data);
            SetisNom('')
            SetisPrenom('')
            SetisTitre('')
            SetisSelectValue('')
            SetisTextarea('')

        }).catch((error) => {
            toast.error(error.message);
        });

    };
    return (
        <div className=' bg-white  h-max flex justify-center items-center'>

            <div className="bg-white temoign m-3 p-2 rounded w-[40em] h-max ">
                <h2 className=' p-2 text-center mb-[1em]'>Mom témoignage</h2>
                <form onSubmit={handleSubmit}>
                    <div class="relative h-11 w-full min-w-[200px] mb-6">
                        <input value={isTitre}
                            onInput={(e) => SetisTitre(e.target.value)} className={`peer  h-full w-full border-b-2 border-green-700  bg-transparent pt-4  font-serif text-1xl font-normal  outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0  disabled:bg-red-500 ${animationClassTitre}`} placeholder=" "
                        />
                        <babel class=" text-gray-500 after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                            Tritre
                        </babel>
                    </div>
                    <div className="flex mb-6 responsive31">
                        <div className="flex w-[70%] justify-between responsive31_temoign separeNamePrenom">
                            <div class="relative h-11 w-1/2 inpust23 mr-2 ">
                                <input value={isNom} onInput={(e) => SetisNom(e.target.value)} className={`peer  h-full w-full border-b-2 border-green-700  bg-transparent pt-4  font-serif text-1xl font-normal  outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0  disabled:bg-red-500 ${animationClassNom}`} placeholder=" " />
                                <label class=" text-gray-500  after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                                    Nom
                                </label>
                            </div>

                            <div class="relative h-11 inpust23 w-1/2">
                                <input value={isPrenom}
                                    onInput={(e) => SetisPrenom(e.target.value)} className={`peer  h-full w-full border-b-2 border-green-700  bg-transparent pt-4  font-serif text-1xl font-normal  outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0  disabled:bg-red-500 ${animationClassPrenom}`} placeholder=" "
                                />
                                <label class="text-gray-500  after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-pink-500 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                                    Prenom
                                </label>
                            </div>
                        </div>


                        <div className={`flex flex-col mx-3 h-11 w-[30%] relative border-b-2 responsive31_temoignSelect ${animationClassSelecteValue}`}>
                            <Select onChange={SetisSelectValue} defaultValue={isSelectValue} options={Options} styles={customStyles} >
                            </Select>
                        </div>

                    </div>


                    <div className="relative h-full w-full mt-[2em]">
                        <textarea value={isTextarea} onChange={(e) => { SetisTextarea(e.target.value); setautoScrollHeight(e.target.scrollHeight); setvalueTextarea(e.target.value.trim().length); if (!(valueTextarea)) { setautoScrollHeight(50) } }} placeholder=" " style={{ height: `${autoScrollHeight}px` }} className={`peer personalTextarea leading-none    w-full border-b-2 border-green-700  py-2 px-1     font-normal resize-none  outline outline-0 transition-all placeholder-shown:border-gray-500 focus:outline-0  disabled:bg-red-500  bg-transparent ${animationClassTextarea}`} />
                        <label className={`pointer-events-none absolute left-1 -top-[25px] peer-fucus:-top-[30px]   peer-placeholder-shown:-top-[10px]   flex h-full w-full select-none text-[15px] font-normal leading-tight text-gray-400 transition-all  -bottom-1.5 blockw-full  duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[15px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:after:scale-x-100 peer-focus:after:border-pink-500 peer-disabled:text-black peer-disabled:peer-placeholder-shown:text-blue-gray-500 `}>
                            Le contenue
                        </label>
                    </div>
                    <div className="flex justify-end items-center">
                        <input type="submit" className="w-max  flex justify-end p-1 bg-orange-600 rounded cursor-pointer px-5 py-1 text-white" id="send"></input>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default FormulaireTemoignVisitores
