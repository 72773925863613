/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react'
function Sloger() {
    const [imageLoading, SetImageLoading] = useState(true)
    const [mobille, SetMobille] = useState(window.innerWidth < 412.938383838838393929203)
    useEffect(() => {
        const timeout = setTimeout(() => {

        }, 1000);

        const hundleSize = () => {
            SetMobille(window.innerWidth < 412.938383838838393929203)
        }

        window.addEventListener('resize', hundleSize);
        return () => {
            clearTimeout(timeout);
            window.removeEventListener('resize', hundleSize)
        }
    }, []);

    return (
        <>
            <div className="w-full h-max p-1 bg-gray-100 border-b rounded-b-[20%] mb-20 pb-20">
                <div className="flex w-full h-full justify-center items-center responsive12">
                    <p className={`w-[40%]  text-center  m-2 flex  flex-col justify-center items-center p-2 h-full ${mobille ? 'text-[25px]' : 'text-[35px]'}    responsive11`}>
                        <h2 className='p-2 font-bold font-serif'><span className='text-green-800'>Ecole d' Excellence</span> <span className='text-blue-900'>Lycée NGAGARA</span></h2>
                        <p className="font-serif text-[20px] w-full text-center">
                            Abîme des sciences, intégrité.
                            Pépinière de hauts cadres responsables et laborieux.
                        </p>
                    </p>
                    <div className=" w-[30em] h-[30em]    border-none     overflow-hidden animeTop slogat  ">
                        {imageLoading && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>}
                        <img draggable='false' onLoad={() => {
                            setTimeout(() => {
                                SetImageLoading(false)
                            }, 1000)
                        }} src={`image/LENGA2.png`} alt="" className='h-full w-full  object-cover object-center' />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Sloger
