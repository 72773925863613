import React from 'react'
import DetailleEvementVisitor from './DetailleEvementVisitor'

function DetailsEventVisitorComponent() {
    return (
        <DetailleEvementVisitor/>
    )
}

export default DetailsEventVisitorComponent
