import React from 'react'
import TemoignageComponent from '../../../component/Visitor/Temoignage/TemoignageComponent'
function TemoignagePage() {
    return (
        <TemoignageComponent/>
            
       
    )
}

export default TemoignagePage
