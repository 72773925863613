export function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 10 ? `0${month}` : month;

    return `${formattedDay}/${formattedMonth}/${year}`;
}
export function transformDate(isoDate) {
    const date = new Date(isoDate);
    const frenchMonths = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'];
    const frenchDays = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'];
    const day = frenchDays[date.getDay()];
    const dayNumber = date.getDate();
    const year = date.getFullYear();
    let hours = date.getHours();
    const minutes = date.getMinutes();
    hours = hours > 9 ? hours : '0' + hours;

    let time = `${hours}h${minutes}`;
    return (
        <div className=' flex'>
            <p className='mx-2 '>
                {day}, {'Le'} {dayNumber}/0{date.getMonth() + 1}/{year}
            </p>
            <p className='ml-2'>
                à {time}
            </p>
        </div>
    );
}

export function chiffrement(entier) {
    const lettreAuHasard = String.fromCharCode(97 + Math.floor(Math.random() * 26));
    const chiffreChiffre = entier * 16823456;
    return chiffreChiffre.toString() + lettreAuHasard;
}

export function dechiffrement(chiffre) {
    const chiffreChiffre = chiffre.slice(0, -1);
    const entier = parseInt(chiffreChiffre) / 16823456;
    return entier;
}