/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom';
import Loading from '../../Loading/Loading';
import { formatDate, chiffrement } from '../../../module';
import ReactPlayer from 'react-player';
import PropTypes from 'prop-types'
import EmpyServices from '../EmpyServeices/EmpyServeices';
function Resource() {
    const [resource, setresource] = useState([]);
    const [isPending, setPending] = useState(true)


    useEffect(() => {
        axios
            .get('https://irakoze.burundientempsreel.com/api/getlastresource')
            .then((response) => {
                setresource(response.data);
                setTimeout(() => {
                    setPending(false)
                }, 100)
            })
            .catch((error) => {
                console.error(error);
            });
    }, []);



    const [mobile1, setMobille1] = useState(window.innerWidth < 1178);
    const [mobile10, setMobille10] = useState(window.innerWidth < 864);
    const [mobile2, setMobille2] = useState(window.innerWidth < 627);
    const [mobile3, setMobille3] = useState(window.innerWidth < 443);
    useEffect(() => {
        const hundleSize = () => {
            setMobille1(window.innerWidth < 1178)
            setMobille10(window.innerWidth < 864)
            setMobille2(window.innerWidth < 627)
            setMobille3(window.innerWidth < 443)
        }
        window.addEventListener('resize', hundleSize)

        return () => {
            window.removeEventListener('resize', hundleSize)
        }
    })




    const [loadingImages, setLoadingImages] = useState([]);

    const handleImageLoad = (index) => {

        setLoadingImages((prevLoadingImages) => {
            const updatedLoadingImages = [...prevLoadingImages];
            updatedLoadingImages[index] = true;
            setTimeout(() => {
                updatedLoadingImages[index] = false;
            }, 50000);
            return updatedLoadingImages;
        });

    };





    const LoadingImage = ({ src, alt, loaderIcon }) => {
        const loaderSrc = loaderIcon
            ? loaderIcon
            : "https://www.eliananunes.com/images/lazy_loader.gif",
            [imgSrc, setImgSrc] = useState(loaderSrc),
            imageRef = useRef(null),
            loader = new Image();

        useEffect(() => {
            //loader is used to run lazyLoad method on load (note: if we removed the loader functionality the lazyLoad function will be triggered only on scroll)
            loader.src = "https://www.eliananunes.com/images/lazy_loader.gif";
            loader.addEventListener("load", checkImage);

            // add the lazyLoad method onscroll
            window.onscroll = window.addEventListener("scroll", lazyLoad);

            return () => {
                // remove the lazyLoad method
                window.removeEventListener("scroll", lazyLoad);
            };
        }, []);

        const checkImage = () => {
            if (loader.complete) {
                lazyLoad();
            }
        };
        const lazyLoad = () => {
            setImgSrc(src);
        };



        return (
            <img draggable='false'
                ref={imageRef}
                src={imgSrc}
                alt={alt}
                className="object-cover border h-full w-full rounded-2xl"
            />
        );
    };

    LoadingImage.propTypes = {
        src: PropTypes.string.isRequired,
        alt: PropTypes.string.isRequired,
        loaderIcon: PropTypes.string,
    };
    return (
        <>
            <div className="  w-[97%]   bg-gray-50 rounded-lg  flex flex-col mt-[20px]  justify-center items-center ">
                {isPending && <Loading />}
                <h2 className='text-[35px] font-serif text-gray-700 px-4 p-1 my-2 rounded '>Ressource</h2>
                <div className='h-[1px] mb-1  bg-gray-300 w-full'></div>
                <div className={`flex flex-wrap  justify-center  gap-2 p-4`}>
                    {resource.length > 0 ? (
                        resource.map((res, index) => {
                            return (
                                <div className={` px-[20px] ${mobile2 ? 'flex-auto h-max' : ''}  bg-white rounded    p-1`}>
                                    <h2 className='font-semibold  w-full'>{res.titre}</h2>
                                    <span className='font-sans text-[13px] text-orange-700'>Le {formatDate(res.createdAt)}</span>
                                    <div className={` m-[2px] ${mobile2 ? 'w-full h-full ' : mobile1 ? 'h-[15em] w-[15em]' : 'h-[20em] w-[20em]'}    border border-orange-700 rounded-2xl overflow-hidden`}>
                                        {res.file && (() => {
                                            const extension = res.file.split('.').pop();
                                            if (extension === 'pdf') {

                                                return (
                                                    <div>PDF introuvable</div>
                                                );
                                            } else if (extension === 'mp4' || extension === 'avi' || extension === 'mov') {

                                                return (

                                                    <>
                                                        <div key={res.id} className='relative border h-full w-full '>
                                                            {loadingImages[index] ? (
                                                                <div className="absolute w-full h-full p-3 inset-0"><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>
                                                            ) :
                                                                (
                                                                    <ReactPlayer
                                                                        url={`https://irakoze.burundientempsreel.com/uploads/resources/${res.file}`}
                                                                        controls
                                                                        className=" inline object-cover object-center w-full h-full"

                                                                    />
                                                                )}

                                                        </div>
                                                    </>


                                                );
                                            } else if (extension === 'jpg' || extension === 'jpeg' || extension === 'png') {

                                                return (
                                                    <div key={res.id} className='relative  h-full w-full '>
                                                        {loadingImages[index] ? (
                                                            <div className="absolute w-full bg-black h-full p-3 inset-0"><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>
                                                        ) :
                                                            (
                                                                <LoadingImage
                                                                    src={`https://irakoze.burundientempsreel.com/uploads/resources/${res.file}`}
                                                                    className="w-full h-full border-none object-contain"
                                                                    onLoad={() => handleImageLoad(index)}
                                                                />
                                                            )}

                                                    </div>
                                                );
                                            }
                                            return null;
                                        })()}
                                    </div>
                                    <div className="w-full mt-1 flex items-center flex-col p-3">

                                        <div className={`min-h-[5em] ${mobile2 ? 'w-full' : 'w-[14em]'}`}>
                                            <div className='w-full  text-center'>
                                                {res.description.length > 100
                                                    ? res.description.slice(0, 100) + '...'
                                                    :
                                                    res.description
                                                }
                                            </div>

                                        </div>
                                        <div className={` ${mobile3 ? 'w-[80%] text-[15px]' : ' w-full text-[20px]'} h-[2.5em]  flex justify-center`}><Link to={`/Visit/Ressource/Detail/${chiffrement(res.id)}`} className='bg-orange-500 transition-all hover:bg-orange-600 text-white px-4 p-1 mt-2 rounded'>En savoir plus</Link>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    ) : (

                        <div className='flex  flex-col items-center w-[50em] border border-orange-600 p-3 rounded-lg bg-slate-100 h-[50vh] widthsssssssss'>
                            <EmpyServices items="de ressources" />
                            <div className='text-[18px] font-sans text-gray-700'>
                                Les ressources dans les écoles secondaires revêtent une importance significative pour l'éducation et le développement des élèves. Elles contribuent à enrichir l'environnement d'apprentissage et à soutenir leur réussite académique et personnelle.
                                <br /><br />
                                Les infrastructures physiques, y compris les salles de classe, les laboratoires, les installations sportives et les bibliothèques, sont des ressources essentielles. Elles offrent un cadre propice à l'apprentissage et à l'exploration. Les laboratoires scientifiques, par exemple, permettent aux élèves de mener des expériences pratiques qui renforcent leur compréhension des sciences.
                            </div>
                        </div>
                    )}






                </div>
                <Link to='/Visit/Ressource' className={`bg-orange-400 ${mobile3 ? 'w-[80%] text-[15px]' : 'w-[20em] text-[20px]'}  mb-4  py-2  text-center rounded-3xl cursor-pointer text-white  hover:border-orange-600 hover:bg-white transition-all hover:text-gray-700 border border-white ${resource.length <= 0 ? 'pointer-events-none opacity-60' : 'pointer-events-auto opacity-100'}`}>Plus de resources</Link>
            </div>

        </>
    )
}

export default Resource
