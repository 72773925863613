import React from 'react'
import Temoignage from './Temoignage'
// import FormulaireTemoignVisitores from './FormulaireTemoignVisitores'

function TemoignageComponent() {
    return (
        <>
            <Temoignage />
            {/* <FormulaireTemoignVisitores /> */}
        </>
    )
}

export default TemoignageComponent
