import React from 'react'

function EmpyServices({ items }) {
    return (

        <div className='text-gray-500 text-[25px] text-center'>
            Pas {items} disponible publier par Lycée d'Excellence NGAGARA.
        </div>
    )
}

export default EmpyServices