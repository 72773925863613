import React from 'react'
import EvenementVoirPlusl from './EvenementVoirPlusl'

function EvenementComponent() {
    return (

        <EvenementVoirPlusl />
    )
}

export default EvenementComponent
