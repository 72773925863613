/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { Link } from 'react-router-dom'


function NavBarsVisitors() {
    const { pathname } = useLocation()
    const [menuClicked, setMenuClicked] = useState(false)
    const [mobile, SetMobile] = useState(window.innerWidth < 658)
    const [mobile3, setMobille3] = useState(window.innerWidth < 443);
    const [imageLoading, SetImageLoading] = useState(true)


    const Events = /^\/Visit\/Evenement.*/
    const Annonces = /^\/Visit\/Annonce.*/
    const Ressource = /^\/Visit\/Ressource.*/

    useEffect(() => {
        const hiddenOnget = () => {
            setMenuClicked(false)
        };

        window.addEventListener('click', hiddenOnget);

        return () => {
            window.removeEventListener('click', hiddenOnget);
        };
    }, []);

    useEffect(() => {
        const hundleSize = () => {
            SetMobile(window.innerWidth < 658)
            setMobille3(window.innerWidth < 443)
        }
        window.addEventListener('resize', hundleSize)

        return () => {
            window.removeEventListener('resize', hundleSize)
        }
    })





    return (
        <>
            {!mobile ? (
                <div className=' h-[10%] bg-[#062c06] relative'>
                    <div className="flex justify-between h-full w-full items-center ">

                        <div className={`transition-all    add w-[70%] right-0  absolute bottom-0  flex justify-around items-end  text-[12px] `}>
                            <div className={` w-[100%] text-[white] scroll  flex justify-around items-end  min-h-full text-[12px] bg-[#062c06]`}>
                                {pathname == "/" ? (
                                    <Link to="/" className=' mx-2 separeIcons p-1 w-[10em] h-[4.2em] rounded-se-lg  rounded-tl-lg pb-2 bg-orange-600 text-white  hover:border-blue-500 border-b-4 border-blue-700 transition-all hover:text-fuchsia-50 flex flex-col justify-center items-center'>
                                        <i class="bi bi-houses-fill text-[20px]"></i>
                                        <p className='sizeText'>Acceuil</p>
                                    </Link>
                                ) : (
                                    <Link to="/" className='mx-2  separeIcons p-1 w-[10em] h-[4.2em] rounded-se-lg  rounded-tl-lg pb-2 hover:bg-orange-400 hover:border-b-[2px] hover:border-blue-500 transition-all hover:text-fuchsia-50 flex flex-col justify-center items-center'>
                                        <i class="bi bi-houses-fill text-[20px]"></i>
                                        <p className='sizeText'>Acceuil</p>
                                    </Link>
                                )


                                }
                                {Events.test(pathname) ? (
                                    <Link to="/Visit/Evenement" className='mx-2 separeIcons p-1 w-[10em]  h-[4.2em] rounded-se-lg  rounded-tl-lg pb-2 bg-orange-600 text-white   border-b-4 border-blue-700 transition-all  flex flex-col justify-center items-center'>
                                        <i class="bi bi-calendar4-event text-[20px]"></i>
                                        <p className='sizeText'>Évenement</p>
                                    </Link>
                                ) : (
                                    <Link to="/Visit/Evenement" className='mx-2 separeIcons p-1 w-[10em] h-[4.2em] rounded-se-lg  rounded-tl-lg pb-2 hover:bg-orange-400 hover:border-b-[2px] hover:border-blue-500 transition-all hover:text-fuchsia-50 flex flex-col justify-center items-center'>
                                        <i class="bi bi-calendar4-event text-[20px]"></i>
                                        <p className='sizeText'>Évenement</p>
                                    </Link>
                                )
                                }

                                {Annonces.test(pathname) ? (
                                    <Link to="/Visit/Annonce" className='mx-2 separeIcons p-1 w-[10em]  h-[4.2em] rounded-se-lg  rounded-tl-lg pb-2 bg-orange-600 text-white   border-b-4 border-blue-700 transition-all  flex flex-col justify-center items-center'>
                                        <i class="bi bi-megaphone-fill text-[20px]"></i>
                                        <p className='sizeText'>Annonce</p>
                                    </Link>
                                ) : (
                                    <Link to="/Visit/Annonce" className='mx-2 separeIcons p-1 w-[10em] h-[4.2em] rounded-se-lg  rounded-tl-lg pb-2 hover:bg-orange-400 hover:border-b-[2px] hover:border-blue-500 transition-all hover:text-fuchsia-50 flex flex-col justify-center items-center'>
                                        <i class="bi bi-megaphone-fill text-[20px]"></i>
                                        <p className='sizeText'>Annonce</p>
                                    </Link>
                                )}


                                {Ressource.test(pathname) ? (
                                    <Link to='/Visit/Ressource' className='mx-2 separeIcons p-1 w-[10em]  h-[4.2em] rounded-se-lg  rounded-tl-lg pb-2 bg-orange-600 text-white   border-b-4 border-blue-700 transition-all  flex flex-col justify-center items-center'>
                                        <i class="bi bi-currency-dollar text-[20px]"></i>
                                        <p className='sizeText'>Ressource</p>
                                    </Link>
                                ) : (
                                    <Link to='/Visit/Ressource' className='mx-2 separeIcons p-1 w-[10em] h-[4.2em] rounded-se-lg  rounded-tl-lg pb-2 hover:bg-orange-400 hover:border-b-[2px] hover:border-blue-500 transition-all hover:text-fuchsia-50 flex flex-col justify-center items-center'>
                                        <i class="bi bi-currency-dollar text-[20px]"></i>
                                        <p className='sizeText'>Ressource</p>
                                    </Link>
                                )}

                                {pathname == "/Visit/Temoignage" || pathname == "/Visit/NouvelleTemoignage" ? (
                                    <Link to="/Visit/Temoignage" className='mx-2 separeIcons p-1 w-[10em]  h-[4.2em] rounded-se-lg  rounded-tl-lg pb-2 bg-orange-600 text-white   border-b-4 border-blue-700 transition-all  flex flex-col justify-center items-center'>

                                        <i class="bi bi bi-badge-tm text-[20px]"></i>
                                        <p className='sizeText'>Témoignage</p>
                                    </Link>
                                ) : (
                                    <Link to="/Visit/Temoignage" className='mx-2 separeIcons p-1 w-[10em] h-[4.2em] rounded-se-lg  rounded-tl-lg pb-2 hover:bg-orange-400 hover:border-b-[2px] hover:border-blue-500 transition-all hover:text-fuchsia-50 flex flex-col justify-center items-center'>

                                        <i class="bi bi bi-badge-tm text-[20px]"></i>
                                        <p className='sizeText'>Témoignage</p>
                                    </Link>
                                )}
                                {pathname == "/Visit/Contact" ? (
                                    <Link to="/Visit/Contact" className='mx-2 separeIcons p-1 w-[10em]  h-[4.2em] rounded-se-lg  rounded-tl-lg pb-2 bg-orange-600 text-white   border-b-4 border-blue-700 transition-all  flex flex-col justify-center items-center'>
                                        <i class="bi bi-envelope-at-fill text-[20px]"></i>
                                        <p className='sizeText'>Suggestion</p>
                                    </Link>
                                ) : (
                                    <Link to="/Visit/Contact" className='mx-2 separeIcons p-1 w-[10em] h-[4.2em] rounded-se-lg  rounded-tl-lg pb-2 hover:bg-orange-400 hover:border-b-[2px] hover:border-blue-500 transition-all hover:text-fuchsia-50 flex flex-col justify-center items-center'>
                                        <i class="bi bi-envelope-at-fill text-[20px]"></i>
                                        <p className='sizeText'>Suggestion</p>
                                    </Link>
                                )}
                                {pathname == "/Visit/Apropos" ? (
                                    <Link to="/Visit/Apropos" className='mx-2 separeIcons p-1 w-[10em]  h-[4.2em] rounded-se-lg  rounded-tl-lg pb-2 bg-orange-600 text-white   border-b-4 border-blue-700 transition-all  flex flex-col justify-center items-center'>
                                        <i class="bi bi-file-person-fill text-[20px]"></i>
                                        <p className='sizeText flex flex-nowrap'>A propos</p>
                                    </Link>
                                ) : (
                                    <Link to="/Visit/Apropos" className='mx-2 separeIcons p-1 w-[10em] h-[4.2em] rounded-se-lg  rounded-tl-lg pb-2 hover:bg-orange-400 hover:border-b-[2px] hover:border-blue-500 transition-all hover:text-fuchsia-50 flex flex-col justify-center items-center'>
                                        <i class="bi bi-file-person-fill text-[20px]"></i>
                                        <p className='sizeText flex flex-nowrap'>A propos</p>
                                    </Link>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className=' h-[10%] bg-[#062c06] py-1 relative'>
                    <div className="flex justify-between h-full w-full items-center ">
                        <Link to='/' className="flex pl-2 items-center ">
                            <div className="w-[45px] h-[45px]  mr-[10px] relative overflow-hidden flex justify-center items-center">
                                {imageLoading && <div className="absolute w-full h-full "><img src="https://www.eliananunes.com/images/lazy_loader.gif" className="w-full h-full object-cover" /></div>}
                                <img draggable='false' onLoad={() => {
                                    setTimeout(() => {
                                        SetImageLoading(false)
                                    }, 1000)
                                }}
                                    src="../../image/LENGA2.png" className='block w-full h-full object-cover object-center' alt="" srcset="" />
                            </div>
                            <div className="">
                                <span className='font-normal text-white anme'><sup>L</sup></span>
                                <span className='font-normal text-white anme'>E</span>
                                <span className='font-normal text-white anme'><sup>N</sup></span>
                                <span className='font-normal text-white anme'>G</span>
                                <span className='font-normal text-white anme'><sup>A</sup></span>
                            </div>
                        </Link>

                        <di className=" flex justify-end w-full ">
                            <button onClick={(e) => { setMenuClicked(!menuClicked); e.stopPropagation() }} className=' w-[4em] h-[4.2em] text-[white]   rounded-tl-lg pb-2  '>
                                <i class="bi bi-list text-[20px] p-2 hover:bg-black transition-all hove:rounded hover:text-fuchsia-50"></i>
                            </button>
                        </di>


                        <div className={`transition-all ${menuClicked ? 'right-0' : ' -right-full'} ${mobile3 ? 'w-full' : 'w-[13em]'} transition-all top-[10vh] h-[90vh] z-50   overflow-y-auto overflow-x-hidden  bg-gray-200  fixed   flex  text-[12px] `}>
                            <div className={` w-[100%] flex-col flex  h-max   text-[12px] `}>
                                {pathname == "/" ? (
                                    <Link to="/" className='  w-full h-12   bg-orange-600 text-white  pl-2  border-b border-gray-50 transition-all hover:text-fuchsia-50 flex  justify-left items-center'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-houses-fill mr-2" viewBox="0 0 16 16">
                                            <path d="M7.207 1a1 1 0 0 0-1.414 0L.146 6.646a.5.5 0 0 0 .708.708L1 7.207V12.5A1.5 1.5 0 0 0 2.5 14h.55a2.51 2.51 0 0 1-.05-.5V9.415a1.5 1.5 0 0 1-.56-2.475l5.353-5.354L7.207 1Z" />
                                            <path d="M8.793 2a1 1 0 0 1 1.414 0L12 3.793V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v3.293l1.854 1.853a.5.5 0 0 1-.708.708L15 8.207V13.5a1.5 1.5 0 0 1-1.5 1.5h-8A1.5 1.5 0 0 1 4 13.5V8.207l-.146.147a.5.5 0 1 1-.708-.708L8.793 2Z" />
                                        </svg>
                                        <p className='sizeText'>Acceuil</p>
                                    </Link>
                                ) : (
                                    <Link to="/" className='  w-full h-12   hover:bg-orange-400   pl-2  border-b border-gray-50 transition-all hover:text-fuchsia-50 flex  justify-left items-center'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-houses-fill mr-2" viewBox="0 0 16 16">
                                            <path d="M7.207 1a1 1 0 0 0-1.414 0L.146 6.646a.5.5 0 0 0 .708.708L1 7.207V12.5A1.5 1.5 0 0 0 2.5 14h.55a2.51 2.51 0 0 1-.05-.5V9.415a1.5 1.5 0 0 1-.56-2.475l5.353-5.354L7.207 1Z" />
                                            <path d="M8.793 2a1 1 0 0 1 1.414 0L12 3.793V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v3.293l1.854 1.853a.5.5 0 0 1-.708.708L15 8.207V13.5a1.5 1.5 0 0 1-1.5 1.5h-8A1.5 1.5 0 0 1 4 13.5V8.207l-.146.147a.5.5 0 1 1-.708-.708L8.793 2Z" />
                                        </svg>
                                        <p className='sizeText'>Acceuil</p>
                                    </Link>
                                )}

                                {Events.test(pathname) ? (
                                    <Link to="/Visit/Evenement" className='  w-full h-12   bg-orange-600 text-white  pl-2  border-b border-gray-50 transition-all hover:text-fuchsia-50 flex  justify-left items-center'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-calendar4-event mr-2" viewBox="0 0 16 16">
                                            <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM2 2a1 1 0 0 0-1 1v1h14V3a1 1 0 0 0-1-1H2zm13 3H1v9a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V5z" />
                                            <path d="M11 7.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1z" />
                                        </svg>
                                        <p className='sizeText'>Évenement</p>
                                    </Link>
                                ) : (
                                    <Link to="/Visit/Evenement" className='  w-full h-12   hover:bg-orange-400   pl-2  border-b border-gray-50 transition-all hover:text-fuchsia-50 flex  justify-left items-center'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-calendar4-event  mr-2" viewBox="0 0 16 16">
                                            <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM2 2a1 1 0 0 0-1 1v1h14V3a1 1 0 0 0-1-1H2zm13 3H1v9a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V5z" />
                                            <path d="M11 7.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1z" />
                                        </svg>
                                        <p className='sizeText'>Évenement</p>
                                    </Link>
                                )


                                }




                                {Annonces.test(pathname) ? (
                                    <Link to="/Visit/Annonce" className='  w-full h-12   bg-orange-600 text-white  pl-2  border-b border-gray-50 transition-all hover:text-fuchsia-50 flex  justify-left items-center'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-megaphone-fill mr-2" viewBox="0 0 16 16">
                                            <path d="M13 2.5a1.5 1.5 0 0 1 3 0v11a1.5 1.5 0 0 1-3 0v-11zm-1 .724c-2.067.95-4.539 1.481-7 1.656v6.237a25.222 25.222 0 0 1 1.088.085c2.053.204 4.038.668 5.912 1.56V3.224zm-8 7.841V4.934c-.68.027-1.399.043-2.008.053A2.02 2.02 0 0 0 0 7v2c0 1.106.896 1.996 1.994 2.009a68.14 68.14 0 0 1 .496.008 64 64 0 0 1 1.51.048zm1.39 1.081c.285.021.569.047.85.078l.253 1.69a1 1 0 0 1-.983 1.187h-.548a1 1 0 0 1-.916-.599l-1.314-2.48a65.81 65.81 0 0 1 1.692.064c.327.017.65.037.966.06z" />
                                        </svg>
                                        <p className='sizeText'>Annonce</p>
                                    </Link>
                                ) : (
                                    <Link to="/Visit/Annonce" className='  w-full h-12   hover:bg-orange-400   pl-2  border-b border-gray-50 transition-all hover:text-fuchsia-50 flex  justify-left items-center'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-megaphone-fill mr-2" viewBox="0 0 16 16">
                                            <path d="M13 2.5a1.5 1.5 0 0 1 3 0v11a1.5 1.5 0 0 1-3 0v-11zm-1 .724c-2.067.95-4.539 1.481-7 1.656v6.237a25.222 25.222 0 0 1 1.088.085c2.053.204 4.038.668 5.912 1.56V3.224zm-8 7.841V4.934c-.68.027-1.399.043-2.008.053A2.02 2.02 0 0 0 0 7v2c0 1.106.896 1.996 1.994 2.009a68.14 68.14 0 0 1 .496.008 64 64 0 0 1 1.51.048zm1.39 1.081c.285.021.569.047.85.078l.253 1.69a1 1 0 0 1-.983 1.187h-.548a1 1 0 0 1-.916-.599l-1.314-2.48a65.81 65.81 0 0 1 1.692.064c.327.017.65.037.966.06z" />
                                        </svg>
                                        <p className='sizeText'>Annonce</p>
                                    </Link>
                                )}






                                {Ressource.test(pathname) ? (
                                    <Link to="/Visit/Ressource" className='  w-full h-12   bg-orange-600 text-white  pl-2  border-b border-gray-50 transition-all hover:text-fuchsia-50 flex  justify-left items-center'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-currency-dollar mr-2" viewBox="0 0 16 16">
                                            <path d="M4 10.781c.148 1.667 1.513 2.85 3.591 3.003V15h1.043v-1.216c2.27-.179 3.678-1.438 3.678-3.3 0-1.59-.947-2.51-2.956-3.028l-.722-.187V3.467c1.122.11 1.879.714 2.07 1.616h1.47c-.166-1.6-1.54-2.748-3.54-2.875V1H7.591v1.233c-1.939.23-3.27 1.472-3.27 3.156 0 1.454.966 2.483 2.661 2.917l.61.162v4.031c-1.149-.17-1.94-.8-2.131-1.718H4zm3.391-3.836c-1.043-.263-1.6-.825-1.6-1.616 0-.944.704-1.641 1.8-1.828v3.495l-.2-.05zm1.591 1.872c1.287.323 1.852.859 1.852 1.769 0 1.097-.826 1.828-2.2 1.939V8.73l.348.086z" />
                                        </svg>
                                        <p className='sizeText'>Ressource</p>
                                    </Link>
                                ) : (
                                    <Link to="/Visit/Ressource" className='  w-full h-12   hover:bg-orange-400   pl-2  border-b border-gray-50 transition-all hover:text-fuchsia-50 flex  justify-left items-center'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-currency-dollar mr-2" viewBox="0 0 16 16">
                                            <path d="M4 10.781c.148 1.667 1.513 2.85 3.591 3.003V15h1.043v-1.216c2.27-.179 3.678-1.438 3.678-3.3 0-1.59-.947-2.51-2.956-3.028l-.722-.187V3.467c1.122.11 1.879.714 2.07 1.616h1.47c-.166-1.6-1.54-2.748-3.54-2.875V1H7.591v1.233c-1.939.23-3.27 1.472-3.27 3.156 0 1.454.966 2.483 2.661 2.917l.61.162v4.031c-1.149-.17-1.94-.8-2.131-1.718H4zm3.391-3.836c-1.043-.263-1.6-.825-1.6-1.616 0-.944.704-1.641 1.8-1.828v3.495l-.2-.05zm1.591 1.872c1.287.323 1.852.859 1.852 1.769 0 1.097-.826 1.828-2.2 1.939V8.73l.348.086z" />
                                        </svg>
                                        <p className='sizeText'>Ressource</p>
                                    </Link>
                                )}







                                {pathname == "/Visit/Temoignage" || pathname == "/Visit/NouvelleTemoignage" ? (
                                    <Link to="/Visit/Temoignage" className='  w-full h-12   bg-orange-600 text-white  pl-2  border-b border-gray-50 transition-all hover:text-fuchsia-50 flex  justify-left items-center'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-badge-tm-fill mr-2" viewBox="0 0 16 16">
                                            <path d="M2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H2zm3.295 3.995V11H4.104V5.995h-1.7V5H7v.994H5.295zM8.692 7.01V11H7.633V5.001h1.209l1.71 3.894h.039l1.71-3.894H13.5V11h-1.072V7.01h-.057l-1.42 3.239h-.773L8.75 7.008h-.058z" />
                                        </svg>
                                        <p className='sizeText'>Témoignage</p>
                                    </Link>
                                ) : (
                                    <Link to="/Visit/Temoignage" className='  w-full h-12   hover:bg-orange-400   pl-2  border-b border-gray-50 transition-all hover:text-fuchsia-50 flex  justify-left items-center'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-badge-tm-fill mr-2" viewBox="0 0 16 16">
                                            <path d="M2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H2zm3.295 3.995V11H4.104V5.995h-1.7V5H7v.994H5.295zM8.692 7.01V11H7.633V5.001h1.209l1.71 3.894h.039l1.71-3.894H13.5V11h-1.072V7.01h-.057l-1.42 3.239h-.773L8.75 7.008h-.058z" />
                                        </svg>
                                        <p className='sizeText'>Témoignage</p>
                                    </Link>
                                )}





                                {pathname == "/Visit/Contact" ? (
                                    <Link to="/Visit/Contact" className='  w-full h-12   bg-orange-600 text-white  pl-2  border-b border-gray-50 transition-all hover:text-fuchsia-50 flex  justify-left items-center'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-envelope-at-fill mr-2" viewBox="0 0 16 16">
                                            <path d="M2 2A2 2 0 0 0 .05 3.555L8 8.414l7.95-4.859A2 2 0 0 0 14 2H2Zm-2 9.8V4.698l5.803 3.546L0 11.801Zm6.761-2.97-6.57 4.026A2 2 0 0 0 2 14h6.256A4.493 4.493 0 0 1 8 12.5a4.49 4.49 0 0 1 1.606-3.446l-.367-.225L8 9.586l-1.239-.757ZM16 9.671V4.697l-5.803 3.546.338.208A4.482 4.482 0 0 1 12.5 8c1.414 0 2.675.652 3.5 1.671Z" />
                                            <path d="M15.834 12.244c0 1.168-.577 2.025-1.587 2.025-.503 0-1.002-.228-1.12-.648h-.043c-.118.416-.543.643-1.015.643-.77 0-1.259-.542-1.259-1.434v-.529c0-.844.481-1.4 1.26-1.4.585 0 .87.333.953.63h.03v-.568h.905v2.19c0 .272.18.42.411.42.315 0 .639-.415.639-1.39v-.118c0-1.277-.95-2.326-2.484-2.326h-.04c-1.582 0-2.64 1.067-2.64 2.724v.157c0 1.867 1.237 2.654 2.57 2.654h.045c.507 0 .935-.07 1.18-.18v.731c-.219.1-.643.175-1.237.175h-.044C10.438 16 9 14.82 9 12.646v-.214C9 10.36 10.421 9 12.485 9h.035c2.12 0 3.314 1.43 3.314 3.034v.21Zm-4.04.21v.227c0 .586.227.8.581.8.31 0 .564-.17.564-.743v-.367c0-.516-.275-.708-.572-.708-.346 0-.573.245-.573.791Z" />
                                        </svg>
                                        <p className='sizeText'>Suggestion</p>
                                    </Link>
                                ) : (
                                    <Link to="/Visit/Contact" className='  w-full h-12   hover:bg-orange-400   pl-2  border-b border-gray-50 transition-all hover:text-fuchsia-50 flex  justify-left items-center'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-envelope-at-fill mr-2" viewBox="0 0 16 16">
                                            <path d="M2 2A2 2 0 0 0 .05 3.555L8 8.414l7.95-4.859A2 2 0 0 0 14 2H2Zm-2 9.8V4.698l5.803 3.546L0 11.801Zm6.761-2.97-6.57 4.026A2 2 0 0 0 2 14h6.256A4.493 4.493 0 0 1 8 12.5a4.49 4.49 0 0 1 1.606-3.446l-.367-.225L8 9.586l-1.239-.757ZM16 9.671V4.697l-5.803 3.546.338.208A4.482 4.482 0 0 1 12.5 8c1.414 0 2.675.652 3.5 1.671Z" />
                                            <path d="M15.834 12.244c0 1.168-.577 2.025-1.587 2.025-.503 0-1.002-.228-1.12-.648h-.043c-.118.416-.543.643-1.015.643-.77 0-1.259-.542-1.259-1.434v-.529c0-.844.481-1.4 1.26-1.4.585 0 .87.333.953.63h.03v-.568h.905v2.19c0 .272.18.42.411.42.315 0 .639-.415.639-1.39v-.118c0-1.277-.95-2.326-2.484-2.326h-.04c-1.582 0-2.64 1.067-2.64 2.724v.157c0 1.867 1.237 2.654 2.57 2.654h.045c.507 0 .935-.07 1.18-.18v.731c-.219.1-.643.175-1.237.175h-.044C10.438 16 9 14.82 9 12.646v-.214C9 10.36 10.421 9 12.485 9h.035c2.12 0 3.314 1.43 3.314 3.034v.21Zm-4.04.21v.227c0 .586.227.8.581.8.31 0 .564-.17.564-.743v-.367c0-.516-.275-.708-.572-.708-.346 0-.573.245-.573.791Z" />
                                        </svg>
                                        <p className='sizeText'>Suggestion</p>
                                    </Link>
                                )}
                                {pathname == "/Visit/Apropos" ? (
                                    <Link to="/Visit/Apropos" className=' w-full h-12   bg-orange-600 text-white  pl-2  transition-all hover:text-fuchsia-50 flex  justify-left items-center'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-file-earmark-person-fill mr-2" viewBox="0 0 16 16">
                                            <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0zM9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1zM11 8a3 3 0 1 1-6 0 3 3 0 0 1 6 0zm2 5.755V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1v-.245S4 12 8 12s5 1.755 5 1.755z" />
                                        </svg>
                                        <p className='sizeText'>A propos</p>
                                    </Link>
                                ) : (
                                    <Link to="/Visit/Apropos" className='  w-full h-12   hover:bg-orange-400   pl-2   transition-all hover:text-fuchsia-50 flex  justify-left items-center'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-file-earmark-person-fill mr-2" viewBox="0 0 16 16">
                                            <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0zM9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1zM11 8a3 3 0 1 1-6 0 3 3 0 0 1 6 0zm2 5.755V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1v-.245S4 12 8 12s5 1.755 5 1.755z" />
                                        </svg>
                                        <p className='sizeText'>A propos</p>
                                    </Link>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}

        </>
    )
}

export default NavBarsVisitors
